import { useState } from "react";
import { NavLink } from "react-router-dom";
//import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
//import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import "./Navbar.css";
import React from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/logo-beta.svg";
import icon from "../../images/logo.svg";
import Divider from "@mui/material/Divider";
import hamburger from "../../images/hamburger.svg";
import { Token } from "@mui/icons-material";

interface NavbarProps {
  isLoggedIn: boolean;
  logout: () => void;
  delete_user: (arg0: string | null) => void;
}
const Navbar = (props: NavbarProps) => {
  const fn = localStorage.getItem("firstname");
  const ln = localStorage.getItem("lastname");
  const email = localStorage.getItem("email");
  const [showNavbar, setShowNavbar] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleAccount = () => {
    setShowAccount(!showAccount);
  };

  return (
    <>
      {props.isLoggedIn ? (
        <nav className="navbar">
          <div className="container">
            <div className="logo">
              <NavLink to="/">
                <img src={logo} width={200} alt="Logo" />
              </NavLink>
            </div>
            <div className="account-button" onClick={handleAccount}>
              <span>{fn}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                style={{ width: "1rem", height: "1rem", marginTop: "4px" }}
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                  clipRule="evenodd"
                />
              </svg>
              <div className={`account-settings ${showAccount && "active"}`}>
                <div>
                  <img src={icon} width={50} alt="Logo" />
                </div>
                <div style={{ fontSize: "18px" }}>
                  {fn} {ln}
                </div>
                <div style={{ fontSize: "13px" }}>{email}</div>
                <Divider
                  sx={{
                    marginBottom: ".5rem",
                    marginTop: ".5rem",
                    borderColor: "gray",
                  }}
                />
                <div onClick={props.logout} className="account-item">
                  Logout
                </div>
                <Divider />
                <div
                  className="account-item"
                  onClick={() => props.delete_user(email)}
                >
                  Delete account
                </div>
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default Navbar;
