import { NavLink } from "react-router-dom";
import "./UserEmail.css";
import { useState } from "react";

import { useParams } from "react-router-dom";

const UserEmail = (props) => {
  const redirectToInternalLink = () => {
    // Replace '/internal-route' with the internal link you want to redirect to
    const internalLink = "/confirmation";

    // Use navigate to redirect to the internal link
    navigate(internalLink);
  };
  let params = useParams();

  const isSymsys = params.community == "symsys" ? true : false;

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const header = {
    fontSize: "24px",
    color: "rgb(29, 34, 2)",
  };

  const itemheader = {
    fontSize: "16px",
    color: "rgb(29, 34, 2)",
    marginBottom: ".5rem",
    display: "block",
    textAlign: "left",
  };

  const half_input = {
    width: "9rem",
    display: "block",
    padding: "5px",
    boxSizing: "border-box",
    border: "solid rgb(29, 34, 2) 2px",
    borderRadius: "5px",
    marginBottom: "10px",
  };

  const subheader = {
    fontSize: "16px",
    color: "gray",
  };

  const input = {
    width: "100%",
    display: "block",
    padding: "5px",
    boxSizing: "border-box",
    border: "solid rgb(29, 34, 2) 2px",
    borderRadius: "5px",
    marginBottom: "10px",
  };

  const submit = {
    width: "8rem",
    padding: "10px",
    boxSizing: "border-box",
    backgroundColor: "#E9F98C",
    borderRadius: "5rem",
    color: "#1D2202",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "1rem",
    border: "2px solid #000",
  };
  return (
    <>
      {isSymsys ? (
        <div className="body">
          <div className="left-side">
            <div className="box-v">
              <div className="content">
                <h2 style={header}>
                  Apply to join Symbolic Systems Alumni Newsletter{" "}
                </h2>
                <p style={subheader}>Powered by symsys.news</p>
                <form onSubmit={props.emailSubmit} method="post">
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={itemheader}>First name *</div>
                        <input
                          style={half_input}
                          onChange={props.enterFirstName}
                          value={props.firstName}
                          required
                        ></input>
                      </div>
                      <div>
                        <div style={itemheader}>Last name *</div>
                        <input
                          style={half_input}
                          onChange={props.enterLastName}
                          value={props.lastName}
                          required
                        ></input>
                      </div>
                    </div>
                    <div style={itemheader}>Email address *</div>
                    <input
                      style={input}
                      onChange={props.enterEmail}
                      type="email"
                      value={props.userEmail}
                      required
                    ></input>
                    <div style={itemheader}>What is your current role? *</div>
                    <input
                      style={input}
                      onChange={props.enterRole}
                      value={props.role}
                      required
                    ></input>
                    <div style={itemheader}>
                      What is your current company? *
                    </div>
                    <input
                      style={input}
                      onChange={props.enterCompany}
                      value={props.company}
                      required
                    ></input>
                    <div style={itemheader}>What is your birthday? *</div>
                    <input
                      style={input}
                      onChange={props.enterBirthday}
                      type="date"
                      value={props.birthday}
                      required
                    ></input>
                    <div style={itemheader}>
                      Which major city is closest to you? *
                    </div>
                    <select
                      style={input}
                      onChange={props.enterCity}
                      value={props.city}
                      required
                    >
                      <option value="">Select a city</option>
                      <option value="New York">New York</option>
                      <option value="San Francisco">San Francisco</option>
                      <option value="Los Angeles">Los Angeles</option>
                      <option value="Chicago">Chicago</option>
                      <option value="Seattle">Seattle</option>
                      <option value="Boston">Boston</option>
                      <option value="Miami">Miami</option>
                      <option value="Phoenix">Phoenix</option>
                      <option value="Austin">Austin</option>
                    </select>
                    <div style={itemheader}>
                      Write a bio introducing yourself to the community (1-2
                      sentences). This will be shared in an upcoming newsletter!{" "}
                    </div>
                    <textarea
                      style={{ ...input, height: "100px" }}
                      onChange={props.enterBio}
                      value={props.bio}
                      required
                    ></textarea>
                    <div style={itemheader}>
                      What do you want to get out of this community? *
                    </div>
                    <textarea
                      style={{ ...input, height: "100px" }}
                      onChange={props.enterGoal}
                      value={props.goal}
                      required
                    ></textarea>
                    <div style={itemheader}>
                      Are you interested in any admin roles in this community? *
                    </div>
                    <select
                      style={input}
                      onChange={props.enterAdminInterest}
                      value={props.adminInterest}
                    >
                      <option value="No">No</option>
                      <option value="Yes">
                        Yes, I'd love to admin this community!
                      </option>
                    </select>
                    <div style={itemheader}>LinkedIn (optional)</div>
                    <input
                      style={input}
                      onChange={props.enterLinkedIn}
                      type="url"
                      value={props.linkedIn}
                    ></input>
                    <div style={itemheader}>Choose community to join *</div>
                    <select
                      id="community"
                      style={input}
                      name="communities"
                      required
                    >
                      <option value="">Select a community</option>
                      <option value="Symsys">Symbolic Systems Alumni</option>
                    </select>
                  </div>
                  <span style={{ display: "block" }}>
                    {" "}
                    <input type="checkbox" required />
                    <span style={{ fontSize: "16px" }}>
                      {" "}
                      I agree to the{" "}
                      <NavLink to="/terms">Terms and Conditions</NavLink>*
                    </span>
                  </span>
                  <span style={{ display: "block" }}>
                    {" "}
                    <input type="checkbox" required />
                    <span style={{ fontSize: "16px" }}>
                      {" "}
                      I will abide by all community guidelines *{" "}
                    </span>
                  </span>
                  <button style={submit}>{props.type}</button>
                </form>
                <span style={{ fontSize: "16px" }}>
                  Already have an account? <NavLink to="/login">Login</NavLink>
                </span>
                {isOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>
                        &times;
                      </span>
                      <h2>Confirmation</h2>
                      <p>
                        Successfully submitted! You will receive an email
                        unfortunately a few minutes later due to our free
                        instance server XD
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="right-side-v">
            <div className="right-box">
              <div className="cartoon">
                <div
                  style={{
                    fontFamily: "EB Garamond",
                    marginBottom: "1rem",
                    fontSize: "18px",
                  }}
                >
                  This is Doug.
                </div>
                <img
                  className="doug"
                  src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/3b2d4fc1-6acb-40c8-c201-e397ae9f9200/public"
                  alt="Cloudflare Image"
                />
                <div
                  style={{
                    fontFamily: "EB Garamond",
                    fontStyle: "italic",
                    marginTop: "1rem",
                    fontSize: "18px",
                  }}
                >
                  Doug is submitting to their collaborative newsletter, powered
                  by SymSys News.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserEmail;
