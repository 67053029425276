import "./App.css";

import "./fonts/avenir.ttf";
import "./fonts/avenir-black.ttf";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") 
);
root.render(
      <App />
);
