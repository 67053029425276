import "./Dashboard.css";
const MemberDashboard = (props) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            marginTop: "5rem",
          }}
        >
          <div className="bento-container">
            {/* box one */}

            <div className="bento" style={{ backgroundColor: "#BFE8FF" }}>
              <div className="txt-center">
                <div className="metric">65</div>
                <div className="metric-txt" style={{ color: "#1175E5" }}>
                  Current Members
                </div>
              </div>
            </div>

            {/* box two */}

            <div className="bento" style={{ backgroundColor: "#EFFFB0" }}>
              <div className="txt-center">
                <div className="metric">Nov 2023</div>
                <div className="metric-txt" style={{ color: "#7A8F03" }}>
                  Creation Date
                </div>
              </div>
            </div>

            {/* box three */}

            <div
              className="bento"
              style={{ backgroundColor: "rgba(255, 199, 243, 1)" }}
            >
              <div className="txt-center">
                <div className="metric">Monthly</div>
                <div className="metric-txt" style={{ color: "#D223A0" }}>
                  Newsletter Publications
                </div>
              </div>
            </div>
          </div>

          {/* announcement */}

          <div className="announce">
            <div className="announce-box">
              <div className="h-announce">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  style={{ marginLeft: "-5px", marginRight: "2px" }}
                >
                  <path
                    d="M15.8125 9.34375V1.4375H17.25V0H5.75V1.4375H7.1875V9.34375C7.1875 9.34375 4.3125 11.5 4.3125 14.375C4.3125 15.0938 7.04375 15.3812 10.0625 15.3812V18.5437C10.0625 19.55 10.35 20.5563 10.7812 21.5625L11.5 23L12.2188 21.5625C12.65 20.7 12.9375 19.6937 12.9375 18.5437V15.3812C15.9562 15.3812 18.6875 14.95 18.6875 14.375C18.6875 11.5 15.8125 9.34375 15.8125 9.34375ZM10.0625 9.4875C10.0625 9.4875 9.34375 9.91875 7.7625 11.5C6.325 12.9375 5.60625 14.2312 5.60625 14.2312C5.60625 14.2312 5.75 12.7937 6.75625 11.5C8.05 9.91875 8.625 9.4875 8.625 9.4875V1.4375H10.0625V9.4875Z"
                    fill="#CF1010"
                  />
                </svg>
                Important Announcement!
              </div>
              {/* <div className= "doug-font">
              (ft.Doug) 
            </div> */}
              <div className="txt-announce">
                We are actively developing and improving symsys.news. Please keep
                an eye out for updates. If you have any feedback, please email
                symsys.alumni@gmail.com
              </div>
            </div>
            {/* <div className="doug-image">
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDashboard;
