import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./home.css";
import image from "../images/news-logo.svg";
import { Link } from "react-router-dom";
import Newsletter from "../app/components/Newsletter";

function Home() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log("entered");
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    console.log("left");
  };
  return (
    <div className="bg">
      <div className="grid">
        <img src={image} className="logo" />
        <div className="hero">
          <div className="left-col">
            <div className="header-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="64"
                viewBox="0 0 60 64"
                fill="none"
                style={{
                  position: "absolute",
                  top: "2rem",
                  zIndex: "20",
                  right: "2rem",
                }}
              >
                <path
                  d="M12.852 58.9369L3.51869 4.34421L53.9216 29.5461L37.7898 36.0446L35.0351 37.1544L36.9316 39.4398L45.2582 49.4742L34.1255 58.133L26.0206 47.4199L24.3832 45.2555L22.6341 47.3307L12.852 58.9369Z"
                  fill="#A5CDFA"
                  stroke="black"
                  strokeWidth="4.33574"
                />
              </svg>{" "}
              <div className="neon-rec">
                <div
                  style={{
                    paddingRight: "2rem",
                    paddingLeft: "2rem",
                    textAlign: "left",
                    lineheight: "137.1%",
                  }}
                >
                  A collaborative newsletter for your community
                </div>
              </div>
              <div className="shadow-rec"></div>
            </div>
            <div className="cta-buttons">
              <Link to="/login">
                <button className="login-button">
                  <div style={{}}>Login</div>
                </button>
              </Link>

              <Link to="/signup/symsys">
                <div className="right-buttons">
                  <button
                    className="start-button"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div style={{ paddingLeft: "1rem" }}>Get Started</div>
                  </button>

                  <div
                    className={`arrow-bg ${isHovered ? "button-hovered" : ""}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="23"
                      viewBox="0 0 38 23"
                      fill="none"
                      style={{ marginTop: ".8rem" }}
                    >
                      <path
                        d="M37.0508 13.0704C37.642 12.4901 37.6508 11.5403 37.0704 10.9492L27.613 1.31551C27.0327 0.724335 26.083 0.715566 25.4918 1.29592C24.9006 1.87627 24.8918 2.82598 25.4722 3.41715L33.8788 11.9804L25.3155 20.387C24.7243 20.9673 24.7156 21.917 25.2959 22.5082C25.8763 23.0994 26.826 23.1082 27.4171 22.5278L37.0508 13.0704ZM0.767034 13.1747L35.9862 13.4999L36.0138 10.5001L0.794733 10.1749L0.767034 13.1747Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="right-col">
            <img
              className="human-img"
              src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/67a5c667-ee83-4093-b11c-b9241c964c00/public"
              alt="Cloudflare Image"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "20rem",
          borderTop: "1px solid",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div className="quote-bg">
          <div className="quote">
            Newsletters have often been a one-person job. <br></br>We want to
            change that.
          </div>
        </div>
      </div>
      <div className="lower-grid">
        <Newsletter></Newsletter>
      </div>
    </div>
  );
}

export default Home;
