import React from 'react'
import { NavLink } from "react-router-dom";
import "./UserEmail.css";





const UserEmail2 = (props) => {


	const header = {
		fontSize: "40px",
		color: "rgb(29, 34, 2)",
	}

	const subheader = {
		fontSize: "18px",
		color: "gray",
	}

	const input = {
		width: "90%",
		padding: "10px",
		boxSizing: "border-box",
		border: "solid rgb(29, 34, 2) 2px",
		borderRadius: "5px",
		marginBottom: "20px"
	}

	const submit = {
		width: "8rem",
		padding: "10px",
		boxSizing: "border-box",
		backgroundColor: "#E9F98C",
		borderRadius: "5rem",
		color: "#1D2202",
		fontSize: "18px",
		marginBottom: "10px",
		marginTop: "1rem",
		border: "2px solid #000"
	}


	const itemheader = {
		fontSize: "22px",
		color: "rgb(29, 34, 2)",
		float: "left",
		marginLeft: "1.5rem",
		marginBottom: ".5rem"
	}


  const enterEmail = (e) => {
    setUserEmail(e.target.value)
  }

   const emailSubmit = (e) => {
    e.preventDefault()
    signIn(userEmail)
    setUserEmail('')
  }

	return (
		<>
			<div className="body">
				<div className='left-side'>
					<div className="box">
						<div className='content'>
						<h2 style={header}>Log in</h2>
						<p style={subheader}>Welcome back to SymSys News!</p>
						<form onSubmit={props.emailSubmit}>
							<span style={itemheader}>Email</span>
							<input onChange={props.enterEmail} style={input} type='email' value={props.userEmail}></input>
							<button style={submit}>Sign in</button>
						</form>
						<span style={{fontSize: "18px" }}>Don't have an account? <NavLink to="/signup/symsys">Sign Up</NavLink></span>
						</div>
					</div>
				</div>
				<div className='right-side'>
				<div className='right-box'>
				 <img
              className="notebook"
              src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/43bdce4e-bf83-4b85-6bad-886ecc3d3d00/public"
              alt="Cloudflare Image"
            />
			</div>
				</div>

				
			</div>
		</>
	)
}

export default UserEmail2