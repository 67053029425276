import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Newsletter.css";

const data = [
  {
    id: 1,
    name: "Max",
    year: "MS '21",
    dec: "I'm organizing a hangout for bay area alums in SF. It's at the Venue, 8pm Dec 1. Please email me to RSVP!",
    img: "https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/bb7fbd44-cf9b-4776-7f86-57c9209a5300/public",
    size: '6'
  },
  {
    id: 2,
    name: "Sid",
    year: "BS '20",
    dec: "My startup is hiring a senior developer...",
    img: "https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/045b8400-9300-4520-6f97-39ac71362600/public",
    size: '4'
  },
  {
    id: 3,
    name: "Hua",
    year: "BS '16",
    dec: "Looking for someone who's been in real estate to talk about their experiences...",
    img: "https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/7c20c5c1-7661-4557-eb26-3a199a69ff00/public",
     size: '4'
  },
  {
    id: 4,
    name: "Javi",
    year: "MS '19",
    dec: "I recently moved to Boston. Looking for furniture, friends, and a bike.",
    img: "https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/8d32a8c4-da4a-4260-67e9-1730a0517400/public",
     size: '6'
  },
];

const Newsletter = () => {
  const [show, setShow] = useState(false);
  const [info, setinfo] = useState();
  // animation for list
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  //  animation for  popup

    const variants2 = {
    visible: {
    opacity: 1, 
      scale: 1.1,
      transition: { duration: 1, type: "spring" },
    y: -200,  
    },
    // Adjust the y position to move it up further

    hidden: { scale: 1, opacity: 0, y: 0,},
  };

  const variants = {
    visible: {
      scale: 1.1,
      boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      y: -50,
      x: -100,
      cursor: "pointer",
      transition: { duration: 1, type: "spring" },
    },
    hidden: { scale: 1, opacity: 0 },
  };
  return (

    <motion.div
    initial="hidden"
  whileInView="visible"
  variants={variants2}
  viewport={{ once: false}}
     onClick={()=> {
            (show==true) && setShow(false)
        }}
     className="container mx-auto letter" style={{position: "relative", width: "60%", height: "60%", top: "400px",
}}>

      <motion.div
        style={{
          filter: show ? "blur(1px)" : "none",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%"
        }}
        className="row d-flex   justify-content-center align-items-center"
        variants={container}
        initial="hidden"
        animate="visible"
       
      >
        <h2 style={{textAlign: "center"}}>Alumni Monthly</h2>

        {data.map((cval) => {
          return (
            <>
              <motion.div
                layout
                className={`col-md-${cval.size} postcard`}
                style={{backgroundColor: "rgba(255, 255, 255, 1)", padding: "1rem", borderRadius: "2rem", border: "16px", borderStyle: "solid", borderColor: "#f6f6f6"}}
                whileHover={() => {
                  setShow(true);
                  setinfo(cval);
                }}
                onHoverEnd={()=>{
                    setShow(false)
                }}
                variants={item}
              >
                <div
                  className="position-relative "
                  style={{ height: "10rem" }}
                >
                  <div style={{display: "flex", gap: "1rem"}}>
                  <img
                    src={cval.img}
                    className="img-fluid profile-pic"
                    alt=""
                  />
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{fontWeight: "500"}}> {cval.name} </div>
                   <div style={{fontSize: "14px"}}>{cval.year}</div>
                  </div>
                  </div>
                  <div style={{fontSize: ".8rem", marginTop: ".5rem"}}>{cval.dec}</div>
                
                </div>
              </motion.div>
            </>
          );
        })}
      </motion.div>

      <AnimatePresence>
        {show && (
          <>
            <motion.div
              style={{
                position: "absolute",
                top: "40%",
                left: "30%",
                backgroundColor: "white",
                minWidth: "8rem"
              }}
          whileHover={() => {
                  setShow(true);
                }}
                onHoverEnd={()=>{
                    setShow(false)
                }}
              variants={variants}
              animate={show ? "visible" : "hidden"}
              exit={{ scale: 1, opacity: 0 }}
            >
              <div style={{display: "flex"}}>
                <img width={100} height={100} src={info.img} alt="" srcset="" />
               <div className="card-title" style={{display: 'flex', flexDirection: "column"}}>
                   <h1> {info.name}</h1>
                  <div style={{fontSize: "16px"}}> {info.year}</div>
                </div>
              </div>
              <p className="card-text">{info.dec}</p>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};


export default Newsletter;