import { useNavigate } from "react-router-dom";

const ChatPage = (props) => {
  let navigate = useNavigate();
  const input = {
    width: "100%",
    padding: "5px",
    boxSizing: "border-box",
    border: "solid rgba(0, 0, 0, 0.4) 1px",
    borderRadius: "5px",
    marginBottom: "20px",
    backgroundColor: "#F5F5F2",
  };

  const choice_input = {
    width: "10rem",
    padding: "5px",
    boxSizing: "border-box",
    //border: "solid rgb(29, 34, 2) 2px",
    //borderRadius: "5px",
    marginBottom: "20px",
    border: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
  };

  const itemheader = {
    display: "block",
    fontSize: "16px",
    color: "rgb(29, 34, 2)",
    marginBottom: ".5rem",
  };

  const itemsubheader = {
    display: "block",
    fontSize: "12px",
    fontFamily: "avenir",
    color: "rgb(29, 34, 2)",
    marginBottom: ".5rem",
  };
  const form = {
    paddingTop: "2rem",
    paddingBottom: "1rem",
  };

  if (props.isAdmin) return <>{props.children}</>;
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "5rem" }}
      >
        <h4 style={{ fontFamily: "new-spirit", textAlign: "center" }}>
          Submit a post
        </h4>
        <form style={form} onSubmit={props.contributionSubmit} method="post">
          <div>
            <label style={itemheader}>Title of post</label>
            <input
              style={input}
              onChange={props.enterTitle}
              type="text"
              required
            ></input>
          </div>
          <div>
            <label for="month" style={itemheader}>
              Which month do you want this post to be included in the
              newsletter?
            </label>
            <label style={itemsubheader}>
              You can schedule posts up to 3 months in advance
            </label>
            <select
              style={choice_input}
              onChange={props.enterDate}
              name="month"
            >
              <option value="2024-08">August</option>
              <option value="2024-09">September</option>
              <option value="2024-10">November</option>
            </select>

            {/* <input
    style={choice_input}
    onChange={props.enterDate}
    onfocus="this.showPicker()"
      id="month"
      name="month"
      defaultValue="2023-09"
      min="2023-09"
      max="2023-11"
      required
      pattern="[0-9]{4}-[0-9]{2}" /> */}
            <span class="validity"></span>
          </div>

          <div>
            <label style={itemheader}>Type of post</label>
            <select
              style={choice_input}
              onChange={props.enterType}
              value={props.postType}
            >
              <option value="Asks" selected>
                Community Asks
              </option>
              <option value="Jobs">Job Board</option>
              <option value="News">In Other News</option>
              <option value="Note">Note to admins</option>
              <option value="Announcement">Admin Announcement</option>
              <option value="Spotlight">Alumni Spotlight</option>
            </select>
          </div>
          <div>
            <label style={itemheader}>Content</label>
            <textarea
              rows="4"
              cols="50"
              style={input}
              onChange={props.enterMessage}
            >
              Enter note here
            </textarea>
          </div>
          <div>
            <label style={itemheader}>Note to admins (optional)</label>
            <textarea
              rows="4"
              cols="50"
              style={input}
              onChange={props.enterNote}
            >
              Enter note here
            </textarea>
          </div>
          <div>
            <label style={itemheader}>Display email?</label>
            <select
              style={choice_input}
              value={props.consent}
              onChange={props.enterConsent}
            >
              <option value="true">Yes</option>
              <option value="false" selected>
                No
              </option>
            </select>
          </div>

          <div>
            <button style={input}>submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatPage;
