//import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ChatPage from "./pages/ChatPage";
import Navbar from "./app/components/Navbar";
import Enter from "./app/components/Enter";
import { useState, useEffect } from "react";
import UserEmail from "./app/components/UserEmail";
import UserEmail2 from "./app/components/UserEmail2";
import AdminDashboard from "./pages/AdminDashboard";
import axios from "axios";
import ManageMembers from "./pages/ManageMembers";

import MemberDashboard from "./pages/Dashboard";
import Sidebar from "./app/components/Sidebar";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Footer from "./app/components/Footer";

const URL = process.env.REACT_APP_SERVER_URL;

function App() {
  let [loggedIn, setLoggedIn] = useState(false);
  let [userEmail, setUserEmail] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [linkedin, setLinkedin] = useState("");
  let [isAdmin, setIsAdmin] = useState(false);
  let [role, setRole] = useState("");
  let [company, setCompany] = useState("");
  let [birthday, setBirthday] = useState("");
  let [city, setCity] = useState("");
  let [bio, setBio] = useState("");
  let [goal, setGoal] = useState("");
  let [adminInterest, setAdminInterest] = useState("");
  let [guidelinePromise, setGuidelinePromise] = useState("");

  let [postType, setPostType] = useState("A");
  let [date, setDate] = useState("2023-10");
  let [title, setTitle] = useState("");
  let [note, setNote] = useState("");
  let [message, setMessage] = useState("");
  let [consent, setConsent] = useState("false");

  // Dealing with the token
  const token = JSON.parse(localStorage.getItem("token"));
  const fn = localStorage.getItem("firstname");
  const ln = localStorage.getItem("lastname");
  const email = localStorage.getItem("email");
  const communities = localStorage.getItem("memberships");

  useEffect(() => {
    const role = localStorage.getItem("role");
    setIsAdmin(role == "admin");
  }, []);

  useEffect(() => {
    const verify_token = async () => {
      if (token === null) return setLoggedIn(false);
      try {
        axios.defaults.headers.common["Authorization"] = token;
        const response = await axios.post(`${URL}/users/verify`);
        return response.data.ok ? login(token) : logout();
      } catch (error) {
        console.log(error);
      }
    };
    verify_token();
  }, []);

  // Sign in, log in, log out
  const login = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    setLoggedIn(true);
    //console.log(`this is name of user ${activeFirstName}`)
  };
  const logout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    setIsAdmin(false);
  };

  const delete_user = async (email) => {
    await axios
      .delete(`${URL}/users/delete/:id`, { params: { id: email } })
      .then((res) => {
        alert("successfully deleted account");
        logout();
      })
      .catch((error) => console.log(error));
  };

  const signIn = async (email, magicLink) => {
    try {
      let res = await axios.post(`${URL}/users/enter`, { email, magicLink });
      if (res.data.token) {
        alert(res.data.message);
        login(res.data.token);
        localStorage.setItem("memberships", res.data.memberships);
        localStorage.setItem("firstname", res.data.firstname);
        localStorage.setItem("lastname", res.data.lastname);
        localStorage.setItem("email", res.data.email);
        //when they have admin right to community
        if (res.data.admin_rights.includes("C0001")) {
          localStorage.setItem("role", "admin");
          setIsAdmin(true);
        }
      } else {
        alert(res.data.message);
      }
    } catch (e) {
      alert(e);
    }
  };
  // ---
  const signUp = async (
    email,
    firstname,
    lastname,
    linkedin,
    role,
    company,
    birthday,
    city,
    bio,
    goal,
    adminInterest,
    guidelinePromise
  ) => {
    try {
      let res = await axios.post(`${URL}/users/create`, {
        email,
        firstname,
        lastname,
        linkedin,
        role,
        company,
        birthday,
        city,
        bio,
        goal,
        adminInterest,
        guidelinePromise,
      });
      if (res.data.ok) {
        alert(res.data.message);
      } else {
        alert("something is wrong with signup");
      }
    } catch (e) {
      alert(e);
    }
  };

  const contribute = async (
    email,
    firstname,
    lastname,
    postType,
    title,
    note,
    message,
    date,
    consent
  ) => {
    try {
      let res = await axios.post(`${URL}/contributions/create`, {
        email,
        firstname,
        lastname,
        postType,
        title,
        note,
        message,
        date,
        consent,
      });
      if (res.data.ok) {
        alert(res.data.message);
      } else {
        alert("your post could not be created");
      }
    } catch (e) {
      alert(e);
    }
  };

  // Event listeners
  const enterEmail = (e) => {
    setUserEmail(e.target.value);
  };
  const enterFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const enterLastName = (e) => {
    setLastName(e.target.value);
  };

  const enterLinkedin = (e) => {
    setLinkedin(e.target.value);
  };

  //
  const enterType = (e) => {
    setPostType(e.target.value);
  };

  const enterDate = (e) => {
    setDate(e.target.value);
  };

  const enterTitle = (e) => {
    setTitle(e.target.value);
  };

  const enterNote = (e) => {
    setNote(e.target.value);
  };
  const enterMessage = (e) => {
    setMessage(e.target.value);
  };
  const enterConsent = (e) => {
    setConsent(e.target.value);
  };
  const enterRole = (e) => {
    setRole(e.target.value);
  };
  const enterCompany = (e) => {
    setCompany(e.target.value);
  };

  const enterBirthday = (e) => {
    setBirthday(e.target.value);
  };

  const enterCity = (e) => {
    setCity(e.target.value);
  };

  const enterBio = (e) => {
    setBio(e.target.value);
  };

  const enterGoal = (e) => {
    setGoal(e.target.value);
  };

  const enterAdminInterest = (e) => {
    setAdminInterest(e.target.value);
  };

  const enterGuidelinePromise = (e) => {
    setGuidelinePromise(e.target.value);
  };

  const contributionSubmit = (e) => {
    e.preventDefault();
    contribute(email, fn, ln, postType, title, note, message, date, consent);
  };

  const emailSubmit = (e) => {
    e.preventDefault();
    signIn(userEmail);
    setUserEmail("");
  };

  const emailSubmit2 = (e) => {
    e.preventDefault();
    signUp(
      userEmail,
      firstName,
      lastName,
      linkedin,
      role,
      company,
      birthday,
      city,
      bio,
      goal,
      adminInterest,
      guidelinePromise
    );
    setUserEmail("");
    setFirstName("");
    setLastName("");
    setLinkedin("");
    setRole("");
    setCompany("");
    setBirthday("");
    setCity("");
    setBio("");
    setGoal("");
    setAdminInterest("");
    setGuidelinePromise("");
  };
  // ---

  return (
    <>
      <BrowserRouter>
        <Navbar
          isLoggedIn={loggedIn}
          logout={logout}
          delete_user={delete_user}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/dashboard"
            element={
              <>
                {!loggedIn ? (
                  <UserEmail2
                    enterEmail={enterEmail}
                    emailSubmit={emailSubmit}
                    userEmail={userEmail}
                  />
                ) : (
                  <div className="member-page">
                    <Sidebar
                      communities={communities}
                      isAdmin={isAdmin}
                      selectedMenu="dashboard"
                    />
                    <MemberDashboard communities={communities} />
                  </div>
                )}
              </>
            }
          />
          <Route
            path="/submit-post/symsys"
            element={
              <>
                {!loggedIn ? (
                  <Home />
                ) : (
                  <div className="member-page">
                    <Sidebar selectedMenu="submit" isAdmin={isAdmin} />
                    <ChatPage
                      loggedIn={loggedIn}
                      isAdmin={false}
                      firstName={fn}
                      lastName={ln}
                      email={email}
                      enterTitle={enterTitle}
                      enterDate={enterDate}
                      enterType={enterType}
                      enterNote={enterNote}
                      enterMessage={enterMessage}
                      enterConsent={enterConsent}
                      contributionSubmit={contributionSubmit}
                      consent={consent}
                      postType={postType}
                    ></ChatPage>{" "}
                  </div>
                )}
              </>
            }
          />
          <Route
            path="signup/:community"
            element={
              <>
                <UserEmail
                  enterEmail={enterEmail}
                  enterFirstName={enterFirstName}
                  enterLastName={enterLastName}
                  emailSubmit={emailSubmit2}
                  userEmail={userEmail}
                  firstName={firstName}
                  lastName={lastName}
                  linkedin={linkedin}
                  role={role}
                  company={company}
                  birthday={birthday}
                  city={city}
                  bio={bio}
                  goal={goal}
                  adminInterest={adminInterest}
                  guidelinePromise={guidelinePromise}
                  setUserEmail={setUserEmail}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  enterLinkedin={enterLinkedin}
                  setLinkedin={setLinkedin}
                  enterRole={enterRole}
                  setRole={setRole}
                  enterCompany={enterCompany}
                  setCompany={setCompany}
                  enterBirthday={enterBirthday}
                  setBirthday={setBirthday}
                  enterCity={enterCity}
                  setCity={setCity}
                  enterBio={enterBio}
                  setBio={setBio}
                  enterGoal={enterGoal}
                  setGoal={setGoal}
                  enterAdminInterest={enterAdminInterest}
                  setAdminInterest={setAdminInterest}
                  enterGuidelinePromise={enterGuidelinePromise}
                  setGuidelinePromise={setGuidelinePromise}
                  type="Sign up"
                />
              </>
            }
          />

          <Route
            path="/login"
            element={
              <>
                <UserEmail2
                  enterEmail={enterEmail}
                  emailSubmit={emailSubmit}
                  userEmail={userEmail}
                />
              </>
            }
          />
          <Route
            path="enter/:email/:link"
            element={<Enter signIn={signIn} />}
          />

          <Route
            path="/send-newsletter"
            element={
              <>
                {!loggedIn ? (
                  <UserEmail2
                    enterEmail={enterEmail}
                    emailSubmit={emailSubmit}
                    userEmail={userEmail}
                  />
                ) : (
                  <div className="member-page">
                    <Sidebar selectedMenu="newsletter" isAdmin={isAdmin} />
                    <ChatPage
                      loggedIn={loggedIn}
                      isAdmin={isAdmin}
                      firstName={fn}
                      lastName={ln}
                      email={email}
                      enterTitle={enterTitle}
                      enterDate={enterDate}
                      enterType={enterType}
                      enterNote={enterNote}
                      enterMessage={enterMessage}
                      enterConsent={enterConsent}
                      contributionSubmit={contributionSubmit}
                      consent={consent}
                      postType={postType}
                    >
                      <AdminDashboard />
                    </ChatPage>{" "}
                  </div>
                )}
              </>
            }
          />

          <Route
            path="/manage-community"
            element={
              <>
                {!loggedIn ? (
                  <UserEmail2
                    enterEmail={enterEmail}
                    emailSubmit={emailSubmit}
                    userEmail={userEmail}
                  />
                ) : (
                  <div className="member-page">
                    <Sidebar selectedMenu="submit" isAdmin={isAdmin} />
                    <ChatPage
                      loggedIn={loggedIn}
                      isAdmin={isAdmin}
                      firstName={fn}
                      lastName={ln}
                      email={email}
                      enterTitle={enterTitle}
                      enterDate={enterDate}
                      enterType={enterType}
                      enterNote={enterNote}
                      enterMessage={enterMessage}
                      enterConsent={enterConsent}
                      contributionSubmit={contributionSubmit}
                      consent={consent}
                      postType={postType}
                    >
                      <ManageMembers />
                    </ChatPage>{" "}
                  </div>
                )}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
