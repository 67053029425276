import axios from "axios";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import "./ManageMembers.css";

const URL = process.env.REACT_APP_SERVER_URL;

const AdminDashboard = (props) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const today = new Date();
  const month = today.getMonth(); //0-based, 7 means August
  const year = today.getFullYear(); //2023
  const day = today.getDate();

  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  let next_or_current = 1;

  if (day == 1) {
    next_or_current = 0;
  }

  const coming_month = new Date(year, month + next_or_current).toDateString();

  const next_month_month = coming_month.split(" ")[1];

  //const next_month_year = coming_month.split(" ")[3];
  const coming_month_string =
    coming_month.split(" ")[3] + "-" + months[next_month_month];

  let [pending, setPendingUsers] = useState([]);
  let [active, setActiveUsers] = useState([]);

  let [community, setCommunity] = useState({});
  //coming month
  let [contributions, setContributions] = useState([]);
  //next coming month

  const getPendingUsers = async (c_id) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .get(`${URL}/users/pending`, { params: { id: c_id } })
      .then((res) => {
        setPendingUsers(res.data.users);
      })
      .catch((error) => console.log(error));
  };

  const getActiveUsers = async (c_id) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .get(`${URL}/users/members`, { params: { id: c_id } })
      .then((res) => {
        setActiveUsers(res.data.users);
      })
      .catch((error) => console.log(error));
  };

  const getContributions = async (c_id, date) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .get(`${URL}/contributions/review`, { params: { id: c_id, date: date } })
      .then((res) => {
        setContributions(res.data.contributions);
      })
      .catch((error) => console.log(error));
  };

  const getCommunityInfo = async (c_id) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .get(`${URL}/communities/search`, { params: { id: c_id } })
      .then((res) => {
        setCommunity(res.data.community);
      })
      .catch((error) => console.log(error));
  };

  const sendTest = async (c_id, date) => {
    axios.defaults.headers.common["Authorization"] = token;
    await axios
      .get(`${URL}/contributions/sendout`, {
        params: { id: c_id, date: date, test: "true" },
      })
      .then((res) => {
        alert(JSON.stringify(res.data.error));
      })
      .catch((error) => console.log(error));
  };

  const sendReal = async (c_id, date) => {
    axios.defaults.headers.common["Authorization"] = token;
    await axios
      .get(`${URL}/contributions/sendout`, {
        params: { id: c_id, date: date, test: "false" },
      })
      .then((res) => {
        alert(JSON.stringify(res.data.error));
      })
      .catch((error) => console.log(error));
  };

  const handleApproval = async (email, c_id) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .put(`${URL}/users/approve`, { email, c_id })
      .then((res) => {
        getPendingUsers("C0001");
        alert(res.data.message);
      })
      .catch((error) => console.log(error));
  };

  const handleReject = async (email, c_id) => {
    axios.defaults.headers.common["Authorization"] = token;

    await axios
      .put(`${URL}/users/reject`, { email, c_id })
      .then((res) => {
        getPendingUsers("C0001");
        alert(res.data.message);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //hardcoding community id
    getPendingUsers("C0001");
    getActiveUsers("C0001");

    getCommunityInfo("C0001");
    getContributions("C0001", coming_month_string);
    console.log(coming_month.split(" ")[3] + "-" + months[next_month_month]);
  }, []);

  //hardcoded community
  const displayPendingUsers =
    pending &&
    pending.map((person) => (
      <tr key={person.Email}>
        <td>
          {person.FirstName} {person.LastName}
        </td>
        <td>{person.Email}</td>
        <td>{person.Linkedin}</td>
        <td>
          <button
            onClick={() => {
              handleApproval(person.Email, "C0001");
              pending.re;
            }}
          >
            Approve
          </button>
          <button
            onClick={() => {
              handleReject(person.Email, "C0001");
            }}
          >
            Reject
          </button>
        </td>
      </tr>
    ));

  const displayActiveUsers =
    active &&
    active.map((person) => (
      <tr key={person.Email}>
        <td>
          {person.FirstName} {person.LastName}
        </td>
        <td>{person.Email}</td>
        <td>{person.Linkedin}</td>
        <td>{person.createdAt}</td>
      </tr>
    ));
  //hardcoded community
  const displayPendingPosts = contributions.map((contribution) => (
    <li key={contribution._id}>
      <div>
        <p>
          {contribution.FirstName} {contribution.LastName}
        </p>
        <p>{contribution.Email}</p>
        <p>{contribution.ScheduledDate}</p>
        <p>{contribution.Title}</p>
        <p>{contribution.Message}</p>
        <p>{contribution.NoteToAdmin}</p>
      </div>
    </li>
  ));

  const displayCommunity = (
    <>
      <p>Community ID: {community.c_id}</p>
      <p>Community name: {community.Name}</p>
      <p>Number of members: {community.NumberOfMembers}</p>
      <p>Member emails:</p>
      {community.MemberEmails &&
        community.MemberEmails.map((email) => (
          <ul>
            <li key={email}>
              <div>
                <p>{email}</p>
              </div>
            </li>
          </ul>
        ))}
    </>
  );

  // const d = new Date();
  // let thisMonth = d.getMonth();

  return (
    <>
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          boxSizing: "border-box",
          backgroundColor: "white",
        }}
      >
        {/* <h1>Welcome to the Admin Dashboard</h1>
        {displayCommunity} */}
        {/* <Divider style={{borderColor: "black", borderWidth: ".1rem", marginBottom: "1rem"}}/> */}
        <div className="pending-members">
          <h4
            style={{
              fontFamily: "new-spirit",
            }}
          >
            Pending Members
          </h4>
          <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            View pending member applications using the dropdrown.
          </p>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Linkedin</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{displayPendingUsers}</tbody>
          </table>
          <div className="pagination">
            {/* Pagination logic would go here */}
            <button>{"<"}</button>
            <button>1</button>
            <button>2</button>
            <button>3</button>
            <button>{">"}</button>
          </div>
        </div>

        <div className="active-members">
          <h4
            style={{
              fontFamily: "new-spirit",
            }}
          >
            Pending Members
          </h4>
          <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            View pending member applications using the dropdrown.
          </p>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Linkedin</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>{displayActiveUsers}</tbody>
          </table>
          <div className="pagination">
            {/* Pagination logic would go here */}
            <button>{"<"}</button>
            <button>1</button>
            <button>2</button>
            <button>3</button>
            <button>{">"}</button>
          </div>
        </div>

        {/* <Divider style={{borderColor: "black", borderWidth: ".1rem", marginBottom: "1rem"}}/> */}
      </div>
    </>
  );
};

export default AdminDashboard;
